import React from 'react'
// import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'

import ContactInfo from './ContactInfo'

// data
// ==========================================================================================
const query = graphql`
  query ContactInfoQuery {
    prismic {
      data: contact(uid: "contact", lang: "en-gb") {
        contactEmail: contact_email
        contactPhone: contact_phone
        contactAddress: contact_address
      }
    }
  }
`

// static query
// ==========================================================================================
const ContactInfoWithQuery = props => (
  <StaticQuery
    // waiting for bug to be resolved https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    query={process.env.GATSBY_ENV === 'development' ? query.toString() : query}
    render={withPreview(
      ({ prismic: { data } }) => (
        <ContactInfo {...data} {...props} />
      ),
      query
    )}
  />
)

export default ContactInfoWithQuery
