import React from 'react'
import { Helmet } from 'react-helmet'

const IubendaCookieBanner = () => {
  return (
    <Helmet>
      <script type="text/javascript">
        {`var _iub = _iub || []; _iub.csConfiguration = {"lang":"en","siteId":1169830,"whitelabel":false,"cookiePolicyId":56298094, "banner":{ "textColor":"black","backgroundColor":"white","slideDown":false,"applyStyles":false }};`}
      </script>
      <script
        type="text/javascript"
        src="//cdn.iubenda.com/cs/iubenda_cs.js"
        charset="UTF-8"
        async
      />
    </Helmet>
  )
}

export default IubendaCookieBanner
