import React from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import { ReactComponent as Svg } from '../images/logo.svg'

const Logo = styled(Svg)`
  position: relative;
  width: 63px;
  height: auto;
  z-index: 120;
`
const Wrapper = styled.div`
  ${Logo} path {
    fill: ${({ isOnWhite, theme }) =>
      isOnWhite ? theme.colors.primary : '#fff'};

    @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
      ${({ isMenuOpen, theme }) =>
        isMenuOpen && `fill: ${theme.colors.primary}`};
    }
  }
`
const Link = styled(GatsbyLink)`
  background: transparent;

  &:hover {
    background: transparent;
  }
`

const LogoComponent = ({ isOnWhite, isMenuOpen }) => {
  return (
    <Wrapper {...{ isOnWhite, isMenuOpen }}>
      <Link to="/">
        <Logo />
      </Link>
    </Wrapper>
  )
}

export default LogoComponent
