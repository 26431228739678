import React, { Fragment } from 'react'
import styled from 'styled-components'

import PhoneSvg from '../images/icon-phone.svg'
import MailSvg from '../images/icon-mail.svg'
import AddressSvg from '../images/icon-address.svg'

// styles
// ==========================================================================================
const LinkWrapper = styled.div`
  margin-bottom: 24px;
  margin-left: 40px;
  padding-left: 0;
`
const Link = styled.a`
  padding-left: 0;

  img {
    position: absolute;
    width: 23px;
    height: 23px;
    left: -40px;
  }
`
const MailIcon = styled.img`
  top: 4px;
  width: 24px;
  height: 17px;
`
const AddressIcon = styled.img`
  width: 18px;
  height: 23px;
`
const Wrapper = styled.div`
  ${LinkWrapper} {
    margin-left: ${({ hasIcons }) => (hasIcons ? '40px' : 0)};
  }
  ${Link} {
    color: ${({ isOnWhite, theme }) =>
      isOnWhite ? theme.colors.primary : '#fff'};
  }
`

// component
// ==========================================================================================
const ContactInfo = ({
  contactEmail,
  contactPhone,
  contactAddress,
  isOnWhite,
  hasIcons,
}) => {
  const removeSpaces = (str = '') => str.split(' ').join('')

  return (
    <Wrapper isOnWhite={isOnWhite} hasIcons={hasIcons}>
      {contactEmail && (
        <LinkWrapper>
          <Link href={`mailto:${contactEmail}`}>
            {hasIcons && <MailIcon src={MailSvg} alt="" />}
            {contactEmail}
          </Link>
        </LinkWrapper>
      )}
      {contactPhone && (
        <LinkWrapper>
          <Link href={`tel:${removeSpaces(contactPhone)}`}>
            {hasIcons && <img src={PhoneSvg} alt="" />}
            {contactPhone}
          </Link>
        </LinkWrapper>
      )}
      {contactAddress && (
        <LinkWrapper>
          <Link
            href={`https://www.google.com/maps/search/xbird,${removeSpaces(
              contactAddress
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {hasIcons && <AddressIcon src={AddressSvg} alt="" />}
            {contactAddress.split(',').map((str, i) => (
              <Fragment key={str}>
                {i > 0 && (
                  <>
                    ,<br />
                  </>
                )}
                {str}
              </Fragment>
            ))}
          </Link>
        </LinkWrapper>
      )}
    </Wrapper>
  )
}

export default ContactInfo
