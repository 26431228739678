import React, { useContext } from 'react'
// import PropTypes from 'prop-types'
import HamburgerMenu from 'react-hamburger-menu'
import styled from 'styled-components'

import MenuItems from './MenuItems'
import { StateContext } from '../context/GlobalStateContext'
import { theme as styleTheme } from '../styles/vars'

// styling
// ==========================================================================================

const Overlay = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #fff;
  z-index: 100;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
`
const HamburgerWrapper = styled.div`
  position: fixed;
  top: 26px;
  right: 7%;
  z-index: 150;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    right: 10.5%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
`
const Wrapper = styled.section`
  --linkColor: ${({ isOnWhite, theme }) =>
    isOnWhite ? theme.colors.navy : '#fff'};

  position: relative;
  display: ${({ open }) => (open ? 'block' : 'none')};
  height: 100vh;
  padding-top: 24px;
  padding-bottom: 72px;
  z-index: 110;
  color: ${({ theme }) => theme.colors.navy};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: block;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
    color: ${({ isOnWhite, theme }) =>
      isOnWhite ? theme.colors.navy : '#fff'};
  }
  svg {
    path {
      fill: ${({ isOnWhite, theme }) =>
        isOnWhite ? theme.colors.navy : '#fff'};
    }
  }
`

// component
// ==========================================================================================
const Menu = ({ isOnWhite, ...props }) => {
  const [{ isMenuOpen }, dispatch] = useContext(StateContext)

  function toggleMenu() {
    dispatch({
      type: 'toggleMenu',
      updates: { isMenuOpen: !isMenuOpen },
    })
  }

  return (
    <>
      <HamburgerWrapper isOnWhite={isOnWhite}>
        <HamburgerMenu
          isOpen={isMenuOpen}
          menuClicked={toggleMenu}
          width={25}
          height={15}
          strokeWidth={1}
          rotate={0}
          color={isOnWhite || isMenuOpen ? styleTheme.colors.navy : '#fff'}
          borderRadius={0}
          animationDuration={0.35}
        />
      </HamburgerWrapper>
      <Wrapper open={isMenuOpen} isOnWhite={isOnWhite}>
        <MenuItems {...props} toggleMenu={toggleMenu} />
      </Wrapper>
      <Overlay open={isMenuOpen} aria-hidden />
    </>
  )
}

Menu.propTypes = {
  // menu: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     link: PropTypes.shape({}).isRequired,
  //   })
  // ).isRequired,
  // lang: PropTypes.string,
}

// Menu.defaultProps = {
//   lang: "en-gb",
//   meta: [],
//   title: "Page",
//   description: "",
//   image: undefined,
// }

export default Menu
