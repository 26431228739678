import React, { useContext } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'

import { StateContext } from '../context/GlobalStateContext'
import Logo from './Logo'
import Menu from '../containers/Menu'

// styles
// ==========================================================================================
const Wrapper = styled.div`
  background: ${({ isOnWhite }) => (isOnWhite ? '#fff' : 'transparent')};
  padding-bottom: 10px;
  position: fixed;
  top: 0;
  left: -5%;
  right: -5%;
  width: 110%;
  max-width: ${({ theme }) => theme.maxWidth.lg}px;
  z-index: 2000;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    top: 0;
    left: 0;
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
  }
`
const LogoWrapper = styled.div`
  margin-top: 24px;
`

// component
// ==========================================================================================
const Header = data => {
  const [{ isMenuOpen, isLogoDark }] = useContext(StateContext)
  // prop that determines if menu uses light or dark theme
  const isOnWhite = !data.heroImage || isLogoDark

  return (
    <Wrapper isOnWhite={isOnWhite}>
      <Row>
        <Col xs={2} xsOffset={1}>
          <LogoWrapper>
            <Logo {...data} {...{ isOnWhite, isMenuOpen }} />
          </LogoWrapper>
        </Col>
        <Col xs={8} xsOffset={1} md={6} mdOffset={0} lg={5} lgOffset={1}>
          <Menu isOnWhite={isOnWhite} />
        </Col>
      </Row>
    </Wrapper>
  )
}

export default Header
