import React from 'react'
import styled from 'styled-components'

// styles
// ==========================================================================================
const Rule = styled.hr`
  margin-top: ${({ small }) => (small ? 19 : 27)}px;
  margin-bottom: ${({ small }) => (small ? 19 : 27)}px;
  border-color: transparent;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: ${({ small }) => (small ? 39 : 63)}px;
    margin-bottom: ${({ small }) => (small ? 39 : 63)}px;
  }
  /* @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: ${({ small }) => (small ? 39 : 88)}px;
    margin-bottom: ${({ small }) => (small ? 39 : 88)}px;
  } */
`

// component
// ==========================================================================================
const Separator = ({ small }) => {
  return <Rule small={small} />
}

export default Separator
