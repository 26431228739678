/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'

import Link from './Link'
import Content from './Content'
import Logo from './Logo'
import Separator from './Separator'
import ContactInfo from './ContactInfoContainer'

// styling
// ==========================================================================================
const Footer = styled.footer`
  --linkColor: #fff;

  padding-top: 40px;
  padding-bottom: 40px;
  background: #0000cc;
  color: #fff;

  a {
    background-size: 20px 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding-top: 112px;
    padding-bottom: 112px;
  }
`
const LogoWrapper = styled.div`
  margin-bottom: 40px;
`
const ColHeading = styled.div`
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 30px;
  opacity: 0.65;
`
const MiscLinkWrapper = styled.div`
  text-align: right;
`
const MiscLink = styled(Link)`
  display: inline-block;
  margin-left: 24px;
`
const CoprightRow = styled(Row)`
  margin-top: 30px;
  margin-bottom: 30px;
  flex-direction: column-reverse;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-bottom: 0;
    flex-direction: row;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-top: 60px;
  }
`
const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 16px;
  }
`

// component
// ==========================================================================================
const Menu = ({
  footerMenuHeading,
  caseStudiesHeading,
  contactHeading,
  footerMenu,
  caseStudies,
  miscellaneousLinks,
  copyright,
}) => {
  const dateRegex = /\{\{\s?year\s?\}\}/
  const currentYear = new Date().getFullYear()

  return (
    <>
      <Separator />
      <Footer>
        <Content>
          <Row>
            <Col xs={8} xsOffset={1} md={2} mdOffset={1}>
              <LogoWrapper>
                <Logo isOnWhite={false} />
              </LogoWrapper>
            </Col>
            <Col xs={8} xsOffset={1} md={2} mdOffset={0}>
              <ColHeading>{footerMenuHeading}</ColHeading>
              <List>
                {footerMenu.map(({ link, label }) => (
                  <li key={label}>
                    <Link link={link}>{label}</Link>
                  </li>
                ))}
              </List>
            </Col>
            <Col xs={8} xsOffset={1} md={2} mdOffset={0}>
              <ColHeading>{caseStudiesHeading}</ColHeading>
              <List>
                {caseStudies.map(({ link, label }) => (
                  <li key={label}>
                    <Link link={link}>{label}</Link>
                  </li>
                ))}
              </List>
            </Col>
            <Col xs={8} xsOffset={1} md={2} mdOffset={0}>
              <ColHeading>{contactHeading}</ColHeading>
              <ContactInfo isOnWhite={false} hasIcons={false} />
            </Col>
          </Row>
          <CoprightRow>
            <Col xs={8} xsOffset={1} sm={4} md={4} mdOffset={1}>
              {copyright && copyright.replace(dateRegex, currentYear)}
            </Col>
            <Col xs={8} xsOffset={1} sm={4} smOffset={0} md={4} mdOffset={0}>
              <MiscLinkWrapper>
                {miscellaneousLinks.map(({ link, label }) => (
                  <MiscLink link={link} key={label}>
                    {label}
                  </MiscLink>
                ))}
              </MiscLinkWrapper>
            </Col>
          </CoprightRow>
        </Content>
      </Footer>
    </>
  )
}

Menu.propTypes = {
  // menu: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     link: PropTypes.shape({}).isRequired,
  //   })
  // ).isRequired,
  // lang: PropTypes.string,
}

// Menu.defaultProps = {
//   lang: "en-gb",
//   meta: [],
//   title: "Page",
//   description: "",
//   image: undefined,
// }

export default Menu
