import React from 'react'
import styled from 'styled-components'

// styles
// ==========================================================================================
const Wrapper = styled.section`
  max-width: ${({ theme }) => theme.maxWidth.lg}px;
  margin: 0 auto;
`

// component
// ==========================================================================================
const Content = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

export default Content
