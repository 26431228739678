// import colors from 'styles/colors'
import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'

const GlobalStyles = createGlobalStyle`
  ${styledNormalize}

  html {
    font-size: 6px;
    /* required for polyfill */
    --scroll-behavior: smooth;
    scroll-behavior: smooth;
    font-size: 16px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: 20px
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      font-size: 25px
    }
  }

  body {
    --linkColor: ${({ theme }) => theme.colors.primary};

    height: 100%;
    min-height: 100%;
    font-size: 16px;
    line-height: 22px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${({ theme }) => theme.colors.navy};
    font-family: ${({ theme }) => theme.fonts.base};

    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: 18px;
      line-height: 30px;
    }
    * {
      box-sizing: border-box;

      /* &::selection {
          background: #FE5F11;
        color: #fff;
      } */
    }
  }

  /*
    A workaround for forcing accessibility wrappers
    to have a 100% height.
    Reach Router issue here: https: //github.com/reach/router/issues/63
    */
  #___gatsby {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
  }
  div[role='group'][tabindex] {
    margin-left: -5%;
    margin-right: -5%;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-left: 0;
    margin-right: 0;
    }
  }
  #___gatsby,
  div[role='group'][tabindex] {
    min-height: 100% !important;
  }

  h1, h2, h3, h4, h5 {
    font-family: ${({ theme }) => theme.fonts.feature};
    font-weight: 300;
    margin: 0;
    hyphens: auto;
  }

  h1 {
    font-size: 48px;
    line-height: 1.16667em;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: 72px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      font-size: 100px;
      line-height: 1.08em;
    }
  }
  h2 {
    font-size: 40px;
    line-height: 1.35em;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: 50px;
    line-height: 1.28em;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      font-size: 70px;
      line-height: 1.14285em;
    }
  }
  h3 {
    font-size: 28px;
    line-height: 1.21429em;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: 36px;
      line-height: 1.33333em
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      font-size: 56px;
      line-height: 1.32143em;
    }
  }
  h4 {
    font-size: 20px;
    line-height: 1.5em;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: 18px;
      line-height: 1.66667em;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      font-size: 30px;
      line-height: 1.4em;
    }
  }
  h5 {
    font-size: 16px;
    line-height: 1.375em;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: 20px;
      line-height: 1.6em
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      font-size: 24px;
      line-height: 1.33333em;
    }
  }


  a {
    position: relative;
    padding-bottom: 3px;
    text-decoration: none;
    color: var(--linkColor);
    background: linear-gradient(to top, var(--linkColor) 0%, var(--linkColor) 100%) 0 bottom repeat-x;
    background-size: 20px 1px;
    transition: background-size 0.2s;

    &:visited {
      color: var(--linkColor);
    }

    /* hover only for non-touch devices */
    @media (pointer: fine) {
      &:hover {
        background-size: 20px 3px;
      }
    }
  }

  .active {    
    /* color: ${({ theme }) => theme.colors.primary} !important; */
  }

  #iubenda-cs-banner {
    bottom: 0px !important;
    left: 0px !important;
    position: fixed !important;
    width: 100% !important;
    z-index: 99999998 !important;
    background-color: black;
  }
  .iubenda-cs-content {
    display: block;
    margin: 0 auto;
    padding: 20px;
    width: auto;
    font-family: Helvetica,Arial,FreeSans,sans-serif;
    font-size: 14px;
    background: #000;
    color: #fff;}
    .iubenda-cs-rationale {
    max-width: 900px;
    position: relative;
    margin: 0 auto;
  }
  .iubenda-banner-content > p {
    font-family: Helvetica,Arial,FreeSans,sans-serif;
    line-height: 1.5;
  }
  .iubenda-cs-close-btn {
    margin:0;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
  }
  .iubenda-cs-cookie-policy-lnk {
    text-decoration: underline;
    color: #fff;
    font-size: 14px;
    font-weight: 900;
  }
`

export default GlobalStyles
