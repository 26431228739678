import React, { useState, useRef } from 'react'
// import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import styled, { css, keyframes } from 'styled-components'

import { linkResolver } from '../utils/linkResolver'
import { ReactComponent as DropdownSvg } from '../images/dropdown.svg'

// animation setup
// ==========================================================================================
function createRevealAnimation({ breakpoint }) {
  const styles = []
  const timing = i => (breakpoint === 'sm' ? i * 50 : i * 100 + 500)

  // use breakpoint to determine if there should be a delay
  for (let i = 0; i < 7; i++) {
    styles.push(`
       &:nth-child(${i}) {
         animation-delay: ${timing(i)}ms;
       }
     `)
  }

  return css`
    ${styles.join('')}
  `
}
const revealFrames = keyframes`
0% {
  opacity:0;
  transform:translate3d(0, -100%, 0);
}
100% {
  opacity: 1;
  transform:translate3d(0, 0, 0);
}`

// styling
// ==========================================================================================
const Nav = styled.nav`
  height: 100%;
  font-size: 18px;
  line-height: 28px;
  font-family: ${({ theme }) => theme.fonts.feature};
  overflow-y: auto;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    overflow-y: visible;
  }
`
const Primary = styled.ul`
  &:first-child {
    padding-top: 32px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: flex;
    justify-content: space-between;
    height: 100%;

    &:first-child {
      padding-top: 24px;
    }
  }
`
const Dropdown = styled.ul`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    position: absolute;
    top: 150%;
    left: 0;
    width: 200%;
    margin-top: 32px;
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  }
`
const MainItem = styled.li`
  display: block;
  margin-bottom: 40px;
  white-space: nowrap;
  align-self: flex-end;
  animation: ${revealFrames} 0.3s ease-out;
  animation-fill-mode: both;
  ${createRevealAnimation({ breakpoint: 'sm' })}

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: inline-block;
    margin-bottom: 0;
  ${createRevealAnimation({ breakpoint: 'md' })}
  animation-duration: 0.6s;
  }
`
const Link = styled(GatsbyLink)`
  display: block;
  color: inherit !important;
  background-size: 20px 0;

  &.active {
    font-weight: bold;
  }
`
const DropdownLabel = styled.div`
  margin-bottom: 16px;
  cursor: default;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: inline-block;
    margin-bottom: 0;
    padding-bottom: 3px;
    color: inherit;
  }
`
const DropdownItem = styled.li`
  margin-bottom: 16px;
  margin-left: 16px;
  max-width: 100%;
  overflow-wrap: break-word;
  white-space: initial;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-left: 0;
  }
`
const DropdownIndicator = styled(DropdownSvg)`
  margin-left: 8px;
  transform: translateY(-3px);
`

// component
// ==========================================================================================
const Menu = ({ menu, toggleMenu }) => {
  const timer = useRef(null)
  const [showDropdown, setShowDropdown] = useState(false)

  function openDropdown() {
    setShowDropdown(true)
  }
  function closeDropdown() {
    setShowDropdown(false)
  }
  function handleMouseOver() {
    clearTimeout(timer.current)
    openDropdown()
  }
  function handleMouseLeave() {
    timer.current = setTimeout(closeDropdown, 500)
  }

  return (
    <Nav>
      <Primary>
        {menu.map(({ primary, dropdown }) =>
          primary.link && primary.link.meta ? (
            <MainItem key={primary.link.meta.id}>
              <Link
                to={linkResolver(primary.link.meta)}
                activeClassName="active"
                onClick={toggleMenu}
              >
                {primary.label}
              </Link>
            </MainItem>
          ) : (
            <MainItem
              onMouseLeave={handleMouseLeave}
              onMouseOver={handleMouseOver}
              onFocus={handleMouseOver}
              key={primary.label}
            >
              <DropdownLabel>
                {primary.label}
                <DropdownIndicator />
              </DropdownLabel>
              <Dropdown open={showDropdown} aria-hidden={showDropdown}>
                {dropdown.map(
                  ({ link, label }) =>
                    link &&
                    link.meta && (
                      <DropdownItem key={link.meta.id}>
                        <Link
                          to={linkResolver(link.meta)}
                          activeClassName="active"
                        >
                          {label}
                        </Link>
                      </DropdownItem>
                    )
                )}
              </Dropdown>
            </MainItem>
          )
        )}
      </Primary>
    </Nav>
  )
}

Menu.propTypes = {
  // menu: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     link: PropTypes.shape({}).isRequired,
  //   })
  // ).isRequired,
  // lang: PropTypes.string,
}

// Menu.defaultProps = {
//   lang: "en-gb",
//   meta: [],
//   title: "Page",
//   description: "",
//   image: undefined,
// }

export default Menu
