import React from 'react'
// import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'

import Footer from '../components/Footer'

// data
// ==========================================================================================
const query = graphql`
  query FooterQuery {
    prismic {
      data: navigation(uid: "navigation", lang: "en-gb") {
        footerMenuHeading: footer_menu_heading
        caseStudiesHeading: case_studies_heading
        contactHeading: contact_heading
        footerMenu: footer_menu {
          label
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
            }
            ... on PRISMIC_Home {
              meta: _meta {
                id
                uid
                type
              }
            }
            ... on PRISMIC_About {
              meta: _meta {
                id
                uid
                type
              }
            }
            ... on PRISMIC_Product {
              meta: _meta {
                id
                uid
                type
              }
            }
            ... on PRISMIC_Blog_main {
              meta: _meta {
                id
                uid
                type
              }
            }
            ... on PRISMIC_Contact {
              meta: _meta {
                id
                uid
                type
              }
            }
            ... on PRISMIC_Generic {
              meta: _meta {
                id
                uid
                type
              }
            }
            ... on PRISMIC_Case_study {
              meta: _meta {
                id
                uid
                type
              }
            }
            ... on PRISMIC__Linkable {
              _linkType
            }
          }
        }
        caseStudies: case_studies {
          label
          link {
            _linkType
            ... on PRISMIC_Case_study {
              meta: _meta {
                id
                uid
                type
              }
            }
          }
        }
        miscellaneousLinks: miscellaneous_links {
          label
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
            }
            ... on PRISMIC_About {
              meta: _meta {
                id
                uid
                type
              }
            }
            ... on PRISMIC_Product {
              meta: _meta {
                id
                uid
                type
              }
            }
            ... on PRISMIC_Contact {
              meta: _meta {
                id
                uid
                type
              }
            }
            ... on PRISMIC_Generic {
              meta: _meta {
                id
                uid
                type
              }
            }
            ... on PRISMIC_Case_study {
              meta: _meta {
                id
                uid
                type
              }
            }
            ... on PRISMIC__Linkable {
              _linkType
            }
          }
        }
      }
      config(uid: "config", lang: "en-gb") {
        copyright
      }
    }
  }
`

// static query
// ==========================================================================================
const FooterWithQuery = props => (
  <StaticQuery
    // waiting for bug to be resolved https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    query={process.env.GATSBY_ENV === 'development' ? query.toString() : query}
    render={withPreview(
      ({ prismic: { data, config } }) => (
        <Footer {...data} {...config} {...props} />
      ),
      query
    )}
  />
)

export default FooterWithQuery
