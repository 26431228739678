import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'

import { imagePropType } from '../utils/prismic'

// data
// ==========================================================================================
const query = graphql`
  query ConfigQuery {
    prismic {
      config(uid: "config", lang: "en-gb") {
        companyName: company_name
        title: title
        description: description
        keywords: keywords
        image: image
      }
    }
  }
`

// component
// ==========================================================================================
const Head = ({
  metaTitle,
  metaDescription,
  metaImage,
  title,
  description,
  image,
  companyName,
}) => {
  const pageTitle = metaTitle || title
  const pageDescription = metaDescription || description
  const pageImage = metaImage || image || {}

  return (
    <Helmet
      htmlAttributes={{ lang: 'en-gb' }}
      title={pageTitle}
      titleTemplate={`%s | ${companyName}`}
      meta={[
        {
          name: 'viewport',
          content:
            'width=device-width, initial-scale=1, user-scalable=no, shrink-to-fit=no',
        },
        { name: `description`, content: pageDescription },
        { property: `og:title`, content: title },
        { property: `og:description`, content: pageDescription },
        { property: `og:type`, content: `website` },
        ...(pageImage.url
          ? [
              { property: `og:image`, content: pageImage.url },
              {
                property: `og:image:width`,
                content: pageImage.dimensions?.width,
              },
              {
                property: `og:image:height`,
                content: pageImage.dimensions?.height,
              },
            ]
          : []),
        { name: `twitter:card`, content: `summary` },
        { name: `twitter:title`, content: title },
        { name: `twitter:description`, content: pageDescription },
      ]}
      link={[
        {
          rel: 'preconnect',
          href: 'https://fonts.gstatic.com',
          crossorigin: true,
        },
        {
          rel: 'preload',
          as: 'style',
          href: encodeURI(
            'https://fonts.googleapis.com/css?family=Exo+2:300|Lato&display=swap'
          ),
        },
        {
          rel: 'stylesheet',
          href: encodeURI(
            'https://fonts.googleapis.com/css?family=Exo+2:300|Lato&display=swap'
          ),
        },
      ]}
      // script={[
      //   { async: true, src: 'https://www.google-analytics.com/analytics.js' },
      // ]}
    />
  )
}

Head.propTypes = {
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  metaImage: imagePropType,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: imagePropType.isRequired,
  companyName: PropTypes.string.isRequired,
}

Head.defaultProps = {
  metaTitle: null,
  metaDescription: null,
  metaImage: null,
}

// static query
// ==========================================================================================
// const MenuWithQuery = () => (
//   <StaticQuery
//     // waiting for bug to be resolved https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
//     query={query}
//     render={withPreview(
//       ({ prismic: { data } }) => (
//         <Menu {...data} />
//       ),
//       query
//     )}
//   />
// )

const HeadWithQuery = pageData => (
  <StaticQuery
    // waiting for bug to be resolved https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    query={process.env.GATSBY_ENV === 'development' ? query.toString() : query}
    render={withPreview(
      siteData => (
        <Head {...siteData.prismic.config} {...pageData} />
      ),
      query
    )}
  />
)

export default HeadWithQuery
