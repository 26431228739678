import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'

// import gridTheme from '../Layout/gridTheme'
// gridTheme.container.maxWidth.xl

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: -5%;
  right: -5%;
  max-width: ${({ theme }) => theme.maxWidth.lg}px;
  margin: 0 auto;
  pointer-events: none;
  z-index: 1000;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    left: 0;
    right: 0;
  }

  * {
    height: 100%;
  }
`

const StyledCol = styled(Col)`
  position: relative;
  color: ${({ theme }) => theme.colors.gray20};
  border-left: 1px solid;
  opacity: 0.25;

  &:last-child {
    border-right: 1px solid;
  }
`

// remember to import the global stylesheet in your project
const VisibleGrid = ({ fluid }) => {
  return (
    <Container>
      <Grid fluid={fluid}>
        <Row>
          <StyledCol xs={2} xsOffset={1} />
          <StyledCol xs={2} />
          <StyledCol xs={2} />
          <StyledCol xs={2} />
        </Row>
      </Grid>
    </Container>
  )
}

VisibleGrid.propTypes = {
  fluid: PropTypes.bool,
}

VisibleGrid.defaultProps = {
  fluid: false,
}

export default VisibleGrid
