import React from 'react'
import styled from 'styled-components'

import Head from './Head'
import GlobalStyle from '../styles/global'
import Header from './Header'
import Footer from '../containers/Footer'
import IubendaCookieBanner from './IubendaCookieBanner'
// import TestGrid from './TestGrid'
import VisibleGrid from './VisibleGrid'

const Container = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  min-height: 100%;
  flex-direction: column;
`
const Main = styled.main`
  flex: 1;
`

const Layout = ({ children, ...data }) => {
  return (
    <Container>
      <GlobalStyle />
      {/* {process.env.GATSBY_ENV === 'development' && <TestGrid fluid />} */}
      <VisibleGrid fluid />

      <Head {...data} />
      <Header {...data} />
      <Main>{children}</Main>
      <Footer />
      <IubendaCookieBanner />
    </Container>
  )
}

export default Layout
