import React from 'react'
// import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'

import Menu from '../components/Menu'

// data
// ==========================================================================================
const query = graphql`
  query MenuQuery {
    prismic {
      data: navigation(uid: "navigation", lang: "en-gb") {
        menu: slices {
          ... on PRISMIC_NavigationSlicesMain_menu_item {
            primary {
              label
              link {
                _linkType
                ... on PRISMIC_Home {
                  meta: _meta {
                    id
                    uid
                    type
                  }
                }
                ... on PRISMIC_About {
                  meta: _meta {
                    id
                    uid
                    type
                  }
                }
                ... on PRISMIC_Product {
                  meta: _meta {
                    id
                    uid
                    type
                  }
                }
                ... on PRISMIC_Contact {
                  meta: _meta {
                    id
                    uid
                    type
                  }
                }
                ... on PRISMIC_Blog_main {
                  meta: _meta {
                    id
                    uid
                    type
                  }
                }
                ... on PRISMIC_Generic {
                  meta: _meta {
                    id
                    uid
                    type
                  }
                }
                ... on PRISMIC_Case_study {
                  meta: _meta {
                    id
                    uid
                    type
                  }
                }
              }
            }
            dropdown: fields {
              label
              link {
                _linkType
                ... on PRISMIC_Case_study {
                  meta: _meta {
                    id
                    uid
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

// static query
// ==========================================================================================
const MenuWithQuery = props => (
  <StaticQuery
    // waiting for bug to be resolved https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    query={process.env.GATSBY_ENV === 'development' ? query.toString() : query}
    render={withPreview(
      ({ prismic: { data } }) => (
        <Menu {...data} {...props} />
      ),
      query
    )}
  />
)

export default MenuWithQuery
